import React from "react";
import theme from "theme";
import { Theme, Link, Image, Strong, Text, Button, Structure } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ZV NETWORK - Roblox development group
			</title>
			<meta name={"description"} content={"We're ZV NETWORK, a Roblox development group that crafts new realities."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Image
			src="https://uploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13:10:59.207Z"
			display="block"
			width="1024px"
			height="1024px"
			text-align="center"
			padding="auto auto auto auto"
			margin="auto auto auto auto"
			hover-opacity="0.5"
			opacity=".5"
			srcSet="https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66a367fee5d2770018968fc1/images/New%20Project-7-2-svg.png?v=2024-07-26T13%3A10%3A59.207Z&quality=85&w=3200 3200w"
			sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
		/>
		<Text
			margin="0px 0px 0px 0px"
			color="#ffffff"
			font="30px rubik"
			text-align="center"
			padding="0px 0px 0px 0px"
		>
			We{" "}
			<Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				craft new realities
			</Strong>
		</Text>
		<Text
			margin="10px 0px 0px 0px"
			color="#ffffff"
			font="25px rubik"
			text-align="center"
			padding="0px 0px 0px 0px"
		>
			We're a game development studio crafting new realities on the Roblox platform.
		</Text>
		<Structure
			cells-number-total="3"
			inner-width="auto"
			height="auto"
			inner-max-width="50"
			inner-min-width="50"
			width="auto"
			padding="20px auto 50px auto"
			margin="20px auto 50px auto"
			justify-content="center"
			flex-wrap="no-wrap"
			align-content="center"
			flex-direction="row"
			align-items="stretch"
			display="flex"
		>
			<Override slot="Content">
				<Override slot="cell-1">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						font="normal 300 16px/1.5 rubik"
						border-width={0}
						border-radius="100px"
						display="block"
						width="100%"
						type="link"
						text-align="center"
						href="https://zedv.net/report-a-bug"
						target="_self"
						text-decoration-line="initial"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Report a bug
						</Strong>
					</Button>
				</Override>
				<Override slot="cell-2">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						border-width={0}
						font="normal 300 16px/1.5 rubik"
						border-radius="100px"
						display="block"
						width="100%"
						target="_self"
						type="button"
						href="https://zedv.net/report-a-player"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Report a player
						</Strong>
					</Button>
				</Override>
				<Override slot="cell-0">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						font="normal 300 16px/1.5 rubik"
						border-width={0}
						border-radius="100px"
						display="block"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							WASTELANDS: LABORATORY
						</Strong>
					</Button>
				</Override>
				<Override slot="Cell 0th" width="100%" pointer-events="auto" />
			</Override>
		</Structure>
		<Text
			margin="50px 0px 0px 0px"
			color="#ffffff"
			font="20px rubik"
			text-align="center"
			padding="0px 0px 0px 0px"
		>
			<Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				QUICK LINKS
			</Strong>
		</Text>
		<Structure
			cells-number-total="3"
			inner-width="auto"
			height="auto"
			inner-max-width="auto"
			inner-min-width="auto"
			width="auto"
			padding="5px auto 50px auto"
			margin="5px auto 50px auto"
			justify-content="center"
			flex-wrap="no-wrap"
			align-content="center"
			flex-direction="row"
			align-items="stretch"
			display="flex"
			lazy-load
		>
			<Override slot="Content" overflow="visible" flex="0 1 auto" display="grid">
				<Override slot="cell-1">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						font="normal 300 16px/1.5 rubik"
						border-width={0}
						border-radius="100px"
						display="block"
						width="100%"
						type="link"
						text-align="center"
						href="https://zedv.net/report-a-bug"
						target="_self"
						text-decoration-line="initial"
					>
						<Strong>
							Contact us
						</Strong>
					</Button>
				</Override>
				<Override slot="cell-2">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						border-width={0}
						font="normal 300 16px/1.5 rubik"
						border-radius="100px"
						display="block"
						width="100%"
						target="_self"
						type="button"
						href="https://zedv.net/report-a-player"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Legal
						</Strong>
					</Button>
				</Override>
				<Override slot="cell-0">
					<Button
						margin="auto auto auto auto"
						padding="auto auto auto auto"
						background="#ffffff"
						color="#000000"
						font="normal 300 16px/1.5 rubik"
						border-width={0}
						border-radius="100px"
						display="block"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Rules
						</Strong>
					</Button>
				</Override>
				<Override slot="Cell 0th" width="100%" pointer-events="auto" />
			</Override>
		</Structure>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66a367fee5d2770018968fbf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});